@import url('https://fonts.googleapis.com/css2?family=Kanit&family=Lato&family=Oswald&family=Roboto&display=swap');

body {
  color: white;
  height: 100%;
  margin: 0;
  font-family: 'Kanit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, #08400e, #012515);
  min-height: 100vh;
}
.navbar {
  background-color: #08400e;
}
.modal-content {
  background-color: #08400e;
}
.modal-header {
  color: #38b6ff;
}
.modal {
  background-color: '#0e0125';
}
.text-green {
  color: #03dd74;
}
.blue-close {
  color: red;
}
.site-container {
  min-height: 100vh;
}
main {
  flex: 1;
  height: 100%;
  margin-bottom: 20px;
}
.btn-primary,
.btn-outline-primary {
  background-color: #212121;
  color: #fff;
}
.btn-follow,
.btn-outline-follow {
  background-color: #2e72d8;
  color: #fff;
  border: none;
  width: 130px;
  height: 34px;
}
.btn-following,
.btn-outline-following {
  background-color: #ceced0;
  color: #fff;
  border: none;
  width: 130px;
  height: 34px;
}

.btn-following:hover,
.btn-outline-primary:hover {
  background-color: rgb(195, 194, 198);
  color: #fff;
}

/* .btn-btn:hover,
.btn-outline-primary:hover {
  background-color: rgb(93, 5, 147);
  color: #fff;
} */
.caret.caret-up {
  border-top-width: 0;
  border-bottom: 4px solid #fff;
}
.products {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rating span {
  color: #ffc000;
}

.product {
  border: 1px #404040 solid;
  margin: 1rem;
}
.product img {
  width: 100%;
  max-width: 500px;
}
.product-info {
  padding: 1rem;
}
.img-large {
  max-width: 100%;
}
.img-xl {
  width: 300;
}
.img-thumbnail {
  height: 80px;
}
.small-container {
  max-width: 600px;
}

.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}
.checkout-steps > div.active {
  border-bottom: 0.2rem solid #f08000;
  color: #f08000;
}

/* sidebar */
.navbar-brand {
  font-weight: bold;
}
.side-navbar {
  width: 240px;
  height: 100%;
  position: absolute;
  left: -300px;
  background-color: #100901;
  transition: 0.5s;
}

.site-container {
  transition: 0.4s;
}
.active-cont {
  margin-left: 240px;
}
.active-nav {
  left: 0;
}
.text-bold {
  font-weight: bold;
}

/* Map */

.full-box {
  height: 100vh;
  max-width: initial;
}
.full-box header,
.full-box footer {
  display: none;
}
.full-box .container {
  height: 100vh;
  max-width: initial;
  margin: 0;
  padding: 0;
}

.full-box .mt-3 {
  margin-top: 0 !important;
}
.card {
  background-color: #0e0125;
  color: white;
}
.map-input-box {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0.5rem auto;
  width: 25rem;
  height: 3rem;
  display: flex;
}
.map-input-box input {
  border-radius: 1rem 0 0 1rem;
  border-right: 0;
}
.map-input-box button {
  border-radius: 0 1rem 1rem 0;
  border-left: 0;
}
button.thumbnail {
  padding: 0;
}
a {
  text-decoration: none;
}
.dog {
  color: #38b6ff;
}
.footer {
  font-family: 'Kanit', sans-serif;
  position: fixed;
  margin-top: 20px;
  padding: 5px;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #08400e;
  color: white;
  text-align: center;
}
.statement {
  padding: 10px;
  margin: auto;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .footer {
    font-family: Arial, sans-serif;
    margin-top: 10px;
    bottom: 0;
  }
  .nav-bar {
    margin-left: 12px;
  }
}
.input-field {
  max-width: 130px;
}
.column-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.search {
  list-style-type: none;
}
.search-container {
  display: flex;
  align-items: center;
}

.search-container input {
  width: 50%;
  padding: 0.35rem;
  border: 1px solid #ccc;
  border-right: none;
  border-radius: 5px 0 0 5px;
  width: 12rem;
}

.search-container button {
  background: #fff;
  border: 1px solid #ccc;
  border-left: none;
  padding: 0.35rem;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}
.walletButton {
  position: fixed;
  bottom: 140px;
}
.update {
  padding-bottom: 70px;
}
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 700px;
}
.products {
  text-align: center;
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.image-item {
  flex: 1 0 200px; /* Adjust the width as needed */
  margin: 10px; /* Adjust the margin as needed */
}

.image-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
p.indent {
  text-indent: 20px;
}
.glow-parent {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #012515;
  color: white;
  text-align: center;
}
.glow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  padding: 0.0001rem;
  margin-top: 5px;
  background: white;
  color: #48abe0;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0 25px 5px #48abe0;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
}

.modal-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
